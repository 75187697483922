var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{staticClass:"add-login--modal",attrs:{"title":_vm.title,"ok-button-props":{ props: { loading: _vm.loading } }},on:{"ok":_vm.handleSubmit,"cancel":_vm.handleCancel},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[(_vm.data)?_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"Login"}},[_c('a-row',{attrs:{"gutter":0}},[_c('a-col',{attrs:{"span":24}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'data.login',
                  {
                    initialValue: _vm.data.code,
                    rules: [
                      { required: true, message: 'Login is required!' },
                      { min: 7, message: 'At least 7 characters' },
                    ]
                  }
                ]),expression:"[\n                  'data.login',\n                  {\n                    initialValue: data.code,\n                    rules: [\n                      { required: true, message: 'Login is required!' },\n                      { min: 7, message: 'At least 7 characters' },\n                    ]\n                  }\n                ]"}],attrs:{"max-length":10,"placeholder":"Enter login"}})],1)],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }